html {
    scroll-behavior: smooth;
}

body {
    background-color: whitesmoke;
    color: #333;
    line-height: 1.5;
    font-family: 'Lora', serif;
    font-size: 1.1rem;
}

@media (max-width: 424px) {
    body {
        font-size: 1rem;
    }
}

a {
    color: #C75040;
    white-space: nowrap;
}

b {
    color: #505050;
}

::selection {
    background: #ccc;
}

li {
    margin: 0.3em 0;
}

*, :after, :before {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
    color: #505050;
    font-family: 'Nunito Sans', sans-serif;
    padding: 0.3em 0;
    margin: 0;
}

button {
    cursor: pointer;
    border-radius: 0;
    border: 1px solid #777;
    background: transparent;
    padding: 3px 15px;
}

section {
    padding: 2em;
    width: 100%;
    overflow-x: auto;
    box-shadow: inset 0px 20px 20px rgba(0, 0, 0, 0.02);
}

section#hi {
    background: initial;
}

section#experience {
    background: rgba(0, 0, 0, 0.04);
}

section#personal {
    background: rgba(0, 0, 0, 0.08);
}

section#education {
    background: rgba(0, 0, 0, 0.12);
}

section#projects {
    background: rgba(0, 0, 0, 0.16);
}

section#trivia {
    background: rgba(0, 0, 0, 0.20);
}

section#footer {
    background: initial;
    font-size: 0.8em;
}

section>div {
    max-width: 768px;
    margin: 0 auto;
}

.year-range {
    font-size: 0.8em;
    color: #757575;
}

.link-logo img {
    margin-right: -0.7em;
    width: 0.7em;
    height: 0.7em;
    vertical-align: baseline;
}
/* Fill the layout with spaces instead of the actual img, to make the "text-decoration: underline" property extend under the image too */
.link-logo::after {
    display: inline-block;
    content: " ";
    text-decoration: underline;
    width: 0.7em;
    overflow: hidden;
    vertical-align: bottom;
}

body.supports-js section>div {
    opacity: 0;
}

body.supports-js section.loaded>div {
    opacity: 1;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}


#isOnWaterPlayground {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
}
#isOnWaterResult {
    white-space: pre-wrap;
}